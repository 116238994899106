<template>
  <div class="container ma-tb-20">
  	<car-state ref='carState'></car-state>
		<div class="bg-fff setting-index">
			<back-button msg="电子病历"></back-button>
			<!-- :rules="rules"  -->
			<el-form  label-position="right" ref="form" label-width="100px" :model="form" class="ma-t-10">
			  <div class="flex f-center width80">
					<el-form-item label="姓名:" prop="Patient">
					  <el-input v-model="form.Patient"></el-input>
					</el-form-item>
					<el-form-item label="性别:" prop="Gender">
					  <el-select v-model="form.Gender" placeholder="请选择性别">
							<el-option label="男" value="1"></el-option>
							<el-option label="女" value="2"></el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="flex f-center width90">
					<el-form-item label="年龄:" prop="Age">
					  <el-input v-model="form.Age" type="number"></el-input>
					</el-form-item>
					<el-form-item label="送达医院:" prop="HospitalName">
					  <el-input v-model="form.HospitalName" style="width: 300px;"></el-input>
					</el-form-item>
				</div>
			  <el-form-item label="主诉:" prop="Symptom">
			    <el-input v-model="form.Symptom"></el-input>
			  </el-form-item>
				<el-form-item label="初步印象:" prop="Preliminary">
				  <el-input v-model="form.Preliminary"></el-input>
				</el-form-item>
				<el-form-item label="病情判断:" prop="DiseaseDiagnosis">
				  <el-radio-group  v-model="form.DiseaseDiagnosis">
						<el-radio label="1" name="DiseaseDiagnosis">轻</el-radio>
						<el-radio label="2" name="DiseaseDiagnosis">中</el-radio>
						<el-radio label="3" name="DiseaseDiagnosis">重</el-radio>
						<el-radio label="4" name="DiseaseDiagnosis">危重</el-radio>
						<el-radio label="5" name="DiseaseDiagnosis">死亡</el-radio>
					</el-radio-group>
				</el-form-item>
				
				<div class="bg-F7 pa-b-10">
					<div class="box-title fon-16 font-bold ma-b-10">到院生命体征</div>
					<el-form-item label="体检:" prop="Medical_T">
						<div class="flex f-center">
							<div class="flex f-center">
								T<el-input class="width10" type="number" v-model="form.Medical_T"></el-input>℃
							</div>
							<div class="flex f-center">
								P<el-input class="width10" type="number" v-model="form.Medical_P"></el-input>次/分
							</div>
							<div class="flex f-center">
								R<el-input class="width10" type="number" v-model="form.Medical_R"></el-input>次/分
							</div>
							<div class="flex f-center">
								BP<el-input class="width10" type="number" v-model="form.Medical_BP"></el-input>mmHg(kpa)
							</div>
						</div>
					</el-form-item>
					
					<el-form-item label="意识:"  prop="Consciousness">
					  <el-radio-group v-model="form.Consciousness">
							<el-radio label="1">正常</el-radio>
							<el-radio label="2">模糊</el-radio>
							<el-radio label="3">瞻妄</el-radio>
							<el-radio label="4">昏迷</el-radio>
							<el-radio label="5">丧失</el-radio>
						</el-radio-group>
					</el-form-item>
					
					<el-form-item label="瞳孔:" prop="Pupil">
					  <el-radio-group v-model="form.Pupil">
							<el-radio label="1">正常</el-radio>
							<el-radio label="2">放大</el-radio>
							<el-radio label="3">缩小</el-radio>
							<el-radio label="4">不等</el-radio>
						</el-radio-group>
					</el-form-item>
					
					<el-form-item label="皮肤:" prop="Skin">
					  <el-radio-group v-model="form.Skin">
							<el-radio label="1">正常</el-radio>
							<el-radio label="2">苍白</el-radio>
							<el-radio label="3">发红</el-radio>
							<el-radio label="4">黄染</el-radio>
							<el-radio label="5">青紫</el-radio>
						</el-radio-group>
					</el-form-item>
					
					<el-form-item label="对光反射:" prop="LightReaction">
					  <el-radio-group v-model="form.LightReaction">
							<el-radio label="1">正常</el-radio>
							<el-radio label="2">迟钝</el-radio>
							<el-radio label="3">消失</el-radio>
						</el-radio-group>
					</el-form-item>
					
					<el-form-item label="必要记录:" prop="HealthExamination">
					  <el-input class="width90 health" type="textarea" v-model="form.HealthExamination"></el-input>
					</el-form-item>
				</div>
				
				<div class="bg-F7 pa-b-10 ma-t-20">
					<div class="box-title fon-16 font-bold ma-b-10">辅助检查</div>
					<el-form-item label="血糖:" prop="BloodSugar">
					  <el-input class="width30 ma-r-10" type="number" v-model="form.BloodSugar"></el-input>mmol/L
					</el-form-item>
					<el-form-item label="血氧饱和度:" prop="SpO2">
					  <el-input class="width30 ma-r-10" type="number" v-model="form.SpO2"></el-input>%
					</el-form-item>
				</div>
				
				<el-form-item class="ma-t-10" label="一般治疗:" prop="GeneralTreatment">
				  <el-checkbox-group v-model="form.GeneralTreatment">
						<el-checkbox label="1" name="GeneralTreatment">吸氧</el-checkbox>
						<el-checkbox label="2" name="GeneralTreatment">吸痰</el-checkbox>
						<el-checkbox label="3" name="GeneralTreatment">心电监护</el-checkbox>
						<el-checkbox label="4" name="GeneralTreatment">除颤、起搏</el-checkbox>
						<el-checkbox label="5" name="GeneralTreatment">包扎止血</el-checkbox>
						<el-checkbox label="6" name="GeneralTreatment">固定(康能、劲托)</el-checkbox>
						<el-checkbox label="7" name="GeneralTreatment">人工呼吸(气囊、呼吸机)</el-checkbox>
						<el-checkbox label="8" name="GeneralTreatment">气管插管</el-checkbox>
						<el-checkbox label="9" name="GeneralTreatment">冰袋冷敷</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				
				<el-form-item label="药物治疗:" prop="DrugTreatment">
				  <el-input v-model="form.DrugTreatment"></el-input>
				</el-form-item>
				
				<el-form-item label="急救医师:" prop="Physician">
				  <el-input v-model="form.Physician"></el-input>
				</el-form-item>
				
				<el-form-item label="">
				  <div class="flex f-center c-fff ma-t-20">
						<!-- <div class="ma-r-10 btn flex f-center f-jcenter" @click="submitForm('form', 1)">保存</div> -->
						<div class="ma-r-10 btn-save btn flex f-center f-jcenter" @click="submitForm('form', 2)">提交</div>
						<div class="btn btn-reset flex f-center f-jcenter" @click="resetForm('form')">重置</div>
					</div>
				</el-form-item>
			</el-form>
		</div>
  </div>
</template>
<script>
let _this;
export default {
  data () {
		var validateInput = (rule, value, callback) => {
			if (value === '' && this.form.SubmitAction == 2) {
				callback(new Error('请输入'));
			} else {
				callback();
			}
		};
		var validateSelect = (rule, value, callback) => {
			if (value === '' && this.form.SubmitAction == 2) {
				callback(new Error('请选择'));
			} else {
				callback();
			}
		};
    return {
			form: {
				TaskId: '',
				Patient: '',
				Gender: '',
				Age: '',
				HospitalName:'',
				Symptom: '',
				Preliminary:'',
				DiseaseDiagnosis:'',
				Medical_T:'',
				Medical_P:'',
				Medical_R:'',
				Medical_BP:'',
				Consciousness:'',
				Skin:'',
				LightReaction:'',
				Pupil:'',
				HealthExamination:'',
				BloodSugar: '',
				SpO2: '',
				GeneralTreatment: [],
				DrugTreatment:'',
				Physician:''
			},
			rules: {
				Patient: [
					{ validator: validateInput },
				],
				Gender: [
					{ validator: validateSelect }
				],
				Age: [
					{ validator: validateInput },
				],
				HospitalName: [
					{ validator: validateInput },
				],
				Symptom: [
					{ validator: validateInput },
				],
				Preliminary: [
					{ validator: validateInput },
				],
				DiseaseDiagnosis: [
					{ validator: validateSelect },
				],
				Medical_T: [
					{ validator: validateInput },
				],
				Medical_P: [
					{ validator: validateInput },
				],
				Medical_R: [
					{ validator: validateInput },
				],
				Medical_BP: [
					{ validator: validateInput },
				],
				Medical_T: [
					{ validator: validateInput },
				],
				Consciousness: [
					{ validator: validateSelect },
				],
				Skin: [
					{ validator: validateSelect },
				],
				LightReaction: [
					{ validator: validateSelect },
				],
				Pupil: [
					{ validator: validateSelect },
				],
				HealthExamination: [
					{ validator: validateInput },
				],
				BloodSugar: [
					{ validator: validateInput },
				],
				SpO2: [
					{ validator: validateInput },
				],
				GeneralTreatment: [
					{ validator: validateSelect },
				],
				DrugTreatment: [
					{ validator: validateInput },
				],
				Physician: [
					{ validator: validateInput },
				],
			}
    }
  },
  created(){
    _this = this;
		_this.form.TaskId = _this.$route.query.id;
		_this.loadData();
  },
	//监听当前页面返回事件
  beforeRouteLeave(to, from, next) {
    // _this.submitForm('form', 1)
    next();
  },
  methods:{
		/**
		 * 获取存储数据
		 */
		async loadData(){
			let _data = await _this.$request('/Task/v1/TaskEmergency/Get?taskId=' + _this.$route.query.id);
			if (_data.Code == 0) {
				_this.form = _data.Data;
				_this.form.TaskId = _this.$route.query.id;
				switch (_this.form.Gender) {
					case '不详':
						_this.form.Gender = '';
						break;
					case '男':
						_this.form.Gender = '1';
						break;
					case '女':
						_this.form.Gender = '2';
						break;
				}
				if (!_this.$validator.isEmpty(_this.form.Consciousness)) {
					_this.form.Consciousness = _this.form.Consciousness.toString();
				}

				if (!_this.$validator.isEmpty(_this.form.DiseaseDiagnosis)) {
					_this.form.DiseaseDiagnosis = _this.form.DiseaseDiagnosis.toString();
				}

				if (!_this.$validator.isEmpty(_this.form.LightReaction)) {
					_this.form.LightReaction = _this.form.LightReaction.toString();
				}

				if (!_this.$validator.isEmpty(_this.form.Pupil)) {
					_this.form.Pupil = _this.form.Pupil.toString();
				}

				if (!_this.$validator.isEmpty(_this.form.Skin)) {
					_this.form.Skin = _this.form.Skin.toString();
				}

				if (!_this.$validator.isEmpty(_this.form.GeneralTreatment)) {
					_this.form.GeneralTreatment = JSON.parse(_this.form.GeneralTreatment);
					_this.form.GeneralTreatment = _this.form.GeneralTreatment.map(res => {
						return res.toString()
					})
				}
			}
		},


		/**
		 * 提交
		 * formName 表单名称
		 * type 1:保存, 2:提交
		 */
		submitForm(formName,type) {
			_this.form.SubmitAction = type;
			_this.$refs[formName].validate((valid) => {
				if (valid) {
					_this.form.GeneralTreatment = JSON.stringify(_this.form.GeneralTreatment);
					_this.$request('/Task/v1/TaskEmergency/Post',{
						method: 'POST',
						data: _this.form

						// TaskId: _this.form.TaskId,
						// 	Patient: _this.form.Patient,
						// 	Gender: _this.form.Gender,
						// 	Age: _this.form.Age,
						// 	HospitalName: _this.form.HospitalName,
						// 	Symptom: _this.form.Symptom,
						// 	Preliminary: _this.form.Preliminary,
						// 	DiseaseDiagnosis: _this.form.DiseaseDiagnosis,
						// 	Medical_T: _this.form.Medical_T,
						// 	Medical_P: _this.form.Medical_P,
						// 	Medical_R: _this.form.Medical_R,
						// 	Medical_BP: _this.form.Medical_BP,
						// 	Consciousness: _this.form.Consciousness,
						// 	Skin: _this.form.Skin,
						// 	LightReaction: _this.form.LightReaction,
						// 	Pupil: _this.form.Pupil,
						// 	HealthExamination: _this.form.HealthExamination,
						// 	BloodSugar: _this.form.BloodSugar,
						// 	SpO2: _this.form.SpO2,
						// 	GeneralTreatment: _this.form.GeneralTreatment,
						// 	DrugTreatment: _this.form.DrugTreatment,
						// 	Physician: _this.form.Physician,
						// 	SubmitAction: _this.form.SubmitAction
					}).then((_data) => {
						if (_data) {
							_this.$toast.success({
								message: _data.Data,
								duration: '1500',
								onClose() {
									_this.$router.go(-1)
								}
							})
						}
					})
					
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		
		// 重置
		resetForm(formName) {
			_this.$refs[formName].resetFields();
		}
  }
}
</script>

<style lang="less" scoped>
	.title{
		margin-left: 0;
	}
	.fon-10{
		font-size: 10px;
	}
	.ma-5{
		margin: 5px 5px 5px 9px;
	}
	.bg-F7{
		background: #F7F7F7;
	}
	.width90{
		width: 90%!important;
	}
	.width30{
		width: 30%!important;
	}
	.setting-index{
		height: auto;
		.box-title{
			color: #5CB9DA;
			padding: 10px;
			border-bottom: 1px solid #5AB8D9;
		}
		.el-form-item{
			margin-bottom: 15px;
			.width10{
				width: 30%!important;
				margin: 0 5px;
			}
			.btn{
				width: 100px;
				height: 35px;
				background: #5CB9DA;
				border-radius: 4px;
			}
			.btn-reset{
				background: #8CBD66;
			}
			.btn-save{
				background: #DA6D58;
			}
		}
	}
	/deep/.el-radio__inner {
	  width:21px;
	  height:20px;
	}
	/deep/ .el-checkbox__inner{
		width:21px;
		height:20px;
	}
</style>